<template>
  <b-sidebar
    :visible="!hideSideBar"
    bg-variant="white"
    shadow
    backdrop
    no-header
    @hidden="setSideBar(false)"
  >
    <b-navbar type="dark" variant="primary">
      <b-navbar-brand>MENU</b-navbar-brand>
    </b-navbar>
    <b-list-group flush>
      <b-list-group-item
        v-for="link in links"
        :key="link.title"
        :to="link.to"
        active-class="active"
      >
        {{ link.title }}
      </b-list-group-item>
      <b-list-group-item disabled>Postagens</b-list-group-item>
    </b-list-group>
  </b-sidebar>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      links: [
        { title: "Cliente", to: "/clientes" },
        { title: "Produtos", to: "/produtos" },
        { title: "Caixa", to: "/caixa" },
        { title: "Representantes", to: "/representantes" },
        { title: "Notificações", to: "/notificacoes" },
        { title: "E-Mails", to: "/emails" },
        { title: "Ligamos para Você", to: "/ligamos-para-voce" },
        { title: "Operações", to: "/operacoes" },
        { title: "Gerenciador", to: "/gerenciador" },
        { title: "Exportações", to: "/exportacoes" },
        { title: "Marcas e Modelos", to: "/marca" },
        { title: "Feedback", to: "/feedback" },
      ],
    };
  },
  computed: {
    ...mapState(["hideSideBar"]),
  },
  methods: {
    ...mapMutations(["setSideBar"]),

    escapeListener(event) {
      if (event.key === "m" && event.ctrlKey) {
        event.preventDefault();
        this.setSideBar(!this.hideSideBar);
      }
    },
  },
  created() {
    window.addEventListener("keydown", this.escapeListener);
  },
  destroyed() {
    window.removeEventListener("keydown", this.escapeListener);
  },
};
</script>

<style lang="scss" scoped>
.sidebar-wrapper {
  width: 299px;
  min-height: 100vh;
  position: fixed;
  display: block;
  transition: ease-out 0.25s;
  overflow: hidden;
  background-color: white;
}

.no-sidebar {
  width: 0px;
}

@media only screen and (max-width: 840px) {
  .sidebar-wrapper {
    position: relative;
    z-index: 90;
  }

  .no-sidebar {
    z-index: 0;
  }
}

.list-group-item {
  &.active {
    border-left-width: 8px;
    background-color: white;
    font-weight: 600;
    color: black;
  }
}
</style>
