<template>
  <div>
    <side-bar />
    <content-container>
      <nav-bar />
      <b-overlay :show="showOverlay">
        <div style="min-height: 100vh; padding-top: 90px">
          <router-view />
        </div>
      </b-overlay>
    </content-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NavBar from "../components/shared/NavBar.vue";
import SideBar from "../components/shared/SideBar.vue";
import ContentContainer from "../components/shared/ContentContainer.vue";

export default {
  components: { NavBar, SideBar, ContentContainer },
  name: "Products",
  computed: {
    ...mapState(["showOverlay"]),
  },
};
</script>

<style scoped>
#wrapper {
  width: 100%;
}
#lvj-router-view {
  padding-top: 40px;
}
</style>
